body {
  margin: 0;
  font-family: -apple-system, blinkmacsystemfont, "segoe ui", "roboto", "oxygen",
    "ubuntu", "cantarell", "fira sans", "droid sans", "helvetica neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f2f5!important;
}

code {
  font-family: source-code-pro, menlo, monaco, consolas, "courier new",
    monospace;
}
