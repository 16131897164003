.ant-layout-header {
  padding: 0 20px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #99b3d0;
  border-radius: 0px 0px 5px 5px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.logo {
  width: 202px;
  height: 31px;
  background-image: url(/img/logo.png);
  background-repeat: no-repeat;
  margin: 16px 28px 16px 0;
  float: left;
  margin-right: 50px;
}

.spanBange {
  margin-left: 5px;
  padding: 1px 7px;
  background: #99b3d0;
  color: #fff;
  border-radius: 50%;
}

.staticAll{
  .ant-card-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-statistic{
      text-align: center;
    }
    .ant-statistic-content-value{
      color: #6b6b6b;
      padding-left: 5px;
    }
  }
}

.formsubmitcenter {
  text-align: center;
  .ant-btn-primary {
    background-color: #77c076;
    border-color: #77c076;
    &:hover,
    &:active {
      background-color: darken(#77c076, 10%);
      border-color: darken(#77c076, 10%);
    }
  }
}

.ant-btn-primary {
  background-color: #99b3d0;
  border-color: #99b3d0;
  &:hover,
  &:active {
    background-color: darken(#99b3d0, 10%);
    border-color: darken(#99b3d0, 10%);
  }
}

.ant-btn-dashed {
  &:hover,
  &:active {
    color: darken(#99b3d0, 10%);
    border-color: darken(#99b3d0, 10%);
  }
}

.header {
  .ant-menu-horizontal {
    background: transparent;
    border-bottom: 1px solid transparent;
    & > .ant-menu-submenu:hover {
      color: #fff;
      border-bottom: 2px solid #fff;
    }
  }
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 2px solid #fff;
    color: #222;
  }
  .ant-menu-item > a:hover {
    color: #222;
  }
  .ant-menu-horizontal > .ant-menu-item-selected {
    border-bottom: 2px solid #fff;
    & > a {
      color: #fff;
      font-weight: bold;
      text-shadow: 0px 1px 0px #4e7097;
    }
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-submenu-title {
    color: #fff;
    font-weight: bold;
    text-shadow: 0px 1px 0px #4e7097;
    & > a {
      color: #fff;
    }
  }
}

.body {
  max-width: 1240px;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}

.contents {
  // background: #fff;
}
.bgg{
  background: #fff;
  .ant-table-pagination.ant-pagination{
    margin-right: 20px;
  }
}
.bg {
  background: #fff;
  & + & {
    margin-top: 30px;
  }
  &.padding {
    padding: 20px;
  }
  &.border {
    border: 1px solid #e5e6e9;
  }
  &.radius {
    border-radius: 5px;
  }
  &-right-line {
    position: absolute;
    right: 0;
    width: 1px;
    height: 100%;
    background: #eaeaea;
    display: block;
  }
}

.AlertInfo.ant-alert-info {
  background-color: #ffffff;
  border: 1px solid #dedede;
  margin-bottom: 15px;
}
.ant-typography.tac {
  text-align: center;
}

.hide {
  display: none !important;
}

.DrawerEdit {
  .ant-drawer-content-wrapper {
    height: 50% !important;
  }
}
.Button-right {
  margin-right: 0;
  margin-left: auto;
  display: inline-block;
}

.DrawerAdd {
  .ant-drawer-content-wrapper {
    width: 600px !important;
  }
}
.Drawer-title {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.DrawerSubmit {
  margin: 0 auto;
  display: block;
}
.DrawerItem {
  .ant-form-item-control-wrapper {
    width: 100%;
  }
}
.dynamic-delete-button + .dynamic-delete-button {
  margin-left: 10px;
}

.col-buttons {
  button {
    width: calc(50% - 20px);
    margin: 10px 10px;
  }
  .Button-full {
    display: table;
    width: 50%;
    margin: 10px auto;
  }
}

.formHeader {
  .ant-form-item-children {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.formHeader_desc {
  width: 100%;
  display: block;
}

.nofondform {
  text-align: center;
  margin: 50px 10px;
  i {
    margin-bottom: 20px;
  }
}

.TransferNew .ant-transfer-list {
  width: 46%;
  height: 350px;
}

.iedit {
  background: #99b3d0;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: darken(#99b3d0, 10%);
    color: #fff;
  }
}

.idel {
  background: #eb7d7d;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: darken(#eb7d7d, 10%);
    color: #fff;
  }
}
.imail {
  background: #8ac89d;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: darken(#8ac89d, 10%);
    color: #fff;
  }
}
.ichart {
  background: #ec82f8;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  color: #fff;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: darken(#ec82f8, 10%);
    color: #fff;
  }
}

.Title-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 10px;
  border-bottom: 1px solid #ececec;
  & + .ant-table-wrapper {
    .ant-pagination {
      padding-right: 20px;
    }
  }
}

.fresult {
  list-style: none;
  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    div {
      &:first-child {
        display: inline-block;
        margin-right: 20px;
        font-weight: bold;
      }
    }
  }
}
